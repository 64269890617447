import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-response',
  templateUrl: './response.component.html',
  styleUrls: ['./response.component.scss']
})
export class ResponseComponent implements OnInit {
  pageUrl: any;

  constructor(private matDialog : MatDialog , @Inject(MAT_DIALOG_DATA) public data: any, private router: Router ) { }

  ngOnInit(): void {
    console.log(this.data);
    this.pageUrl = this.router.url;
    console.log(this.pageUrl);
  }

  close(){
    this.matDialog.closeAll();
    
  }

}