import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

const headers = new HttpHeaders().set('Content-Type','application/json');
@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  // BaseURL = 'http://192.168.0.19:8000/api/'
    BaseURL = 'https://litrackapi.complywelltechnologies.com/api/'

  constructor(private router: Router,private http: HttpClient) { }

  login(loginDate: any) {
    return this.http.post(`${this.BaseURL}login/`, loginDate,{headers});
  }

  isLoggedIn(){
    return sessionStorage.getItem('user_id') !== null;
  }

  logout(data: any) {
    return this.http.post(`${this.BaseURL}logout/`, data);
  }


}
