import { Router } from '@angular/router';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class TokenAuthService  implements HttpInterceptor{

  private httpClient:any

  constructor( private route:Router,private handler: HttpBackend) { 
    this.httpClient = new HttpClient(this.handler)
  }

  pincodelocation(pin:number){
    return this.httpClient.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${pin}&key=AIzaSyAwK5M-wbwk062VBi_FDf47tMPPglxnp6k`);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let tokenPass = req;
    if(sessionStorage.getItem('token')){
      tokenPass = req.clone({
        setHeaders:{
          'Authorization' : `token ${sessionStorage.getItem('token')}`
        }
      })
    }
      return next.handle(tokenPass).pipe(catchError((err: HttpErrorResponse ) => {
        if(err.error.detail == 'Invalid token.'){
          sessionStorage.clear();
          this.route.navigate(['/lit/login']).then(()=>{
            console.log('Session got expired login again');
            sessionStorage.setItem('tokenexpired','true');
          });
        }
        console.log(err.error.detail)
        return throwError(err.error)
      })
  )
}

}