import { AuthServiceService } from 'src/app/auth-service.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{

  constructor(private authService: AuthServiceService,private router: Router) { 

  }

  canActivate(): boolean{
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['lit/login']);
      return false;
    }
    return true;
  }
}
